* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Syne", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0px;
  padding: 0px;
}
img{
  max-width: 100%;
  height: auto;
}
.navbarWraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: solid 1px #CBCBCB;
}

.main-padding {
  padding: 80px 0px;
}

.navLinks ul {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: end;
}

.navbarWraper .medLogo img {
  width: 120px;
}
.navbarWraper .login {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  cursor: pointer;
  height: 100%;
  justify-content: end;
}

.navbarWraper .login .user-image img {
  width: 40px;
}
.navbarWraper .login .dropdown-icon img {
  width: 20px;
}

.navbarWraper .login .menu ul li {
  list-style: none;
  margin-bottom: 10px;
}
.navbarWraper .login .menu ul li:last-child {
  margin: 0;
}
.navbarWraper .login .menu ul li a {
  text-decoration: none;
  list-style: none;
  color: #000;
}
.navbarWraper .login .menu ul li a:hover {
  color: #443173;
}

.navbarWraper .login .menu ul {
  padding: 0;
  margin: 0;
}
.navbarWraper .login .menu ul li a.unsub-link {
  padding: 10px 20px;
  background: #ea7f49;
  border-radius: 10px;
}

.navbarWraper .login .menu {
  position: absolute;
  top: 50px;
  right: 0px;
  background: aliceblue;
  width: 190px;
  border-radius: 20px;
  min-width: 210px;
  border-radius: 15px;
  padding: 20px 13px;
}
.navLinks ul li {
  margin-right: 20px;
  font-size: 18px;
}
.navLinks ul li img {
}
.navLinks ul li a {
  color: #000;
  text-decoration: none;
}
.navLinks ul li:last-child {
  margin: 0px;
}
.navLinks ul li.active a {
  border-bottom: 2px solid #d65f7b;
  padding-bottom: 5px;
}
a.subscribe {
  padding: 12px 25px;
  background: #ea7f49;
  border-radius: 10px;
  font-size: 15px;
  color: #fff !important;
  text-decoration: none;
  transition: 0.2s;
  display: block;
  cursor: pointer;
  text-align: center;
}
a.subscribe:hover {
  opacity: 0.7;
}
.bannerHead h1 {
  margin-bottom: 20px;
}

.bannerSec .sub-heading h1 {
  font-size: 40px;
  font-weight: bold;
}
.bannerHead h1,
.bannerHead span {
  font-weight: 600;
  font-size: 44px;
  line-height: 52px;
  color: #443173;
}

.bannerHead span {
  color: #d65f7b;
  margin: 10px 0px;
}

.bannerText p {
  font-weight: 400;
  font-size: 24px;
  line-height: 149%;
  color: #443173;
  margin: 20px 0px;
}

.bannerSec .row {
  align-items: center;
}

.contactBtn {
  padding: 11px 10px;
  width: 100%;
  max-width: 190px;
  background: #ea7f49;
  border-radius: 4px;
  font-weight: 400;
  font-size: 17px;
  color: #ffffff;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  border-radius: 50px;
}
.contactBtn:hover {
  color: #fff;
  transition: 0.2s;
  opacity: 0.6;
}
.contactBtn i {
  font-size: 14px;
  margin-left: 10px;
}
.bannerBtn {
  margin-top: 12px;
}
.con-button {
  margin-top: 12px;
}

/* health sec end */

.offerSec {
  padding: 20px;
  background: #443173;
}

/* doctor-section */
.doctor-section .doctor-content {
  text-align: center;
  padding: 30px;
  width: 25%;
}

.doctor-section .doc-image {
  padding: 12px 0px;
}
p {
  margin-bottom: 0px !important;
}
.doctor-section .doc-heading {
  color: #443173;
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 24px;
  min-height: 72px;
}
.doc-sub {
  min-height: 72px;
}
.text {
  min-height: 256px;
}
.doctor-section button {
  background: #e47f49;
  border: none;
  color: #fff;
  padding: 10px 30px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 18px;
}

.doctor-section .doctor-content.doc {
  background: #e6e4eb;
  padding: 30px;
}
.doctor-section {
  display: flex;
}

.doctor-section .doc-con-text {
  width: 80%;
  margin: auto;
}

/* footer */
.footer {
  background: #4e427f;
  color: #fff;
  padding: 30px 0px;
}

.footer .footer-text {
  margin-top: 25px;
  color: #dddddd;
}
.footer .icons {
  margin-bottom: 5px;
}
.footer a {
  color: #ddd;
  text-decoration: none;
  font-size: 18px;
}

.footer .foo-heading {
  font-size: 25px;
  margin-bottom: 15px;
  font-weight: 800;
}

.footer .contact-text {
  color: #ddd;
  font-size: 18px;
}

.footer .social-link .icons {
  /* padding: 10px 0px; */
}

/* .footer .icons i{
  padding-left: 10px;
} */

.footer .link a {
  color: white;
}

.footer .link .icons {
  /* padding: 5px 0px; */
}

/* service */

.service-text {
  text-align: center;
  color: white;
  font-size: 40px;
  font-weight: bold;
}

.service-text img {
  width: auto;
}

.icon span {
  min-width: px;
  display: inline-block;
}
.main-container-alert-modal {
  /* position: relative; */
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
}

.custom-cross {
  position: absolute;
  top: 14px;
  right: 20px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 9%;
}
.custom-x-icon {
  color: #3a3a3a;
  width: 20px;
  height: 20px;
}
.mediqhref:hover {
  font-weight: 600;
  color: #fff;
}
/* responsive */
@media (min-width: 340px) and (max-width: 767px) {
  /* header */

  .navbarWraper .login .menu {
    top: 57px;
    right: 11px;
  }
  .doctor-section .doc-image img {
    width: 100%;
  }
  .main-padding {
    padding: 50px 0;
  }
  .doctor-section {
    flex-direction: column;
  }
  .service-text {
    font-size: 30px;
  }

  .service-text img {
    width: auto;
  }

  section.offerSec {
    margin-top: 40px;
  }

  .bannerSec .sub-heading h1 {
    font-size: 37px;
    font-weight: bold;
  }
  .doctor-section .doc-heading {
    /* font-size: 24px; */
  }
  .icon span {
    min-width: 100px;
    display: inline-block;
  }
  .navbarWraper .medLogo {
    width: 80px;
  }

  .doctor-section .doc-image {
    padding: 30px 0px;
  }

  .navLinks ul li {
    display: none;
  }
  .navLinks ul li:last-child {
    display: block;
  }
  a.subscribe {
    padding: 8px 20px;
  }
  .bannerBtn {
    text-align: center;
  }
  .bannerHead h1,
  .bannerHead span {
    font-size: 39px;
    line-height: 32px;
    margin-bottom: 10px;
    margin-top: 24px;
  }
  .bannerText p {
    text-align: justify;
    font-size: 18px;
  }

  .doctor-section .doctor-content {
    width: 100%;
  }

  .contactBtn {
    padding: 10px 5px;

    border-radius: 50px;
  }

  .footer .footer-logo .image img {
    width: auto;
  }
  .footer .footer-text {
    margin-top: 15px;
  }
  .footer .foo-heading {
    margin: 15px 0 5px 0;
  }
  .footer {
    padding: 30px 20px;
  }
}
