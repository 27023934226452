.subscription-plan {
  background-image: url("../Assets/subscription-plan/desktop-bg.webp");
  background-size: cover;
  background-position: center;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.subscription-plan .sub-right img {
  width: 400px;
  position: absolute;
  bottom: 0;
  right: 50px;
}
.subscription-plan span.sub {
  color: #443172;
}

.subscription-plan .heading {
  font-size: 45px;
  font-weight: 700;
}

.subscription-plan span.pla {
  color: #cf348c;
}
.subscription-plan .sub-text {
  margin-top: 15px;
}

.subscription-plan .subscription-card {
  display: flex;
  gap: 20px;
  margin-top: 50px;
}

.subscription-plan .subscription-card .purple-card {
  background-image: url("../Assets/subscription-plan/card-colored-bg.webp");
  background-size: cover;
  background-position: center;
  padding: 25px 25px;
  border-radius: 30px;
}

.subscription-plan .subscription-card img {
  width: 70px;
}

.subscription-plan .subscription-card .card-heading {
  font-size: 23px;
  font-weight: 700;
  color: #fff;
  padding-top: 15px;
}

.subscription-plan .subscription-card .des {
  color: #fff;
  font-size: 11px;
  padding-top: 12px;
  width: 98%;
}

.subscription-plan .subscription-card .price {
  color: #fff;
  margin-top: 18px;
  font-weight: 600;
  font-size: 20px;
}

.subscription-plan .subscription-card .price span.day {
  color: #a9a9a9;
  font-size: 15px;
}

.subscription-plan .subscription-card .link {
  margin-top: 35px;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.subscription-plan .subscription-card .link a {
  background: #cf348c;
  text-decoration: none;
  color: #fff;
  padding: 10px;
  border-radius: 50px;
  display: inline-block;
  width: 90%;
}

.subscription-plan .subscription-card .link a.disabled {
  pointer-events: none;
  cursor: default;
  background: #a9a9a9;
  color: black;
}

.subscription-plan .subscription-card .white-card {
  background-image: url("../Assets/subscription-plan/tahaffuz_plan.webp");
  border: 1px solid #41346f;
}

.subscription-plan .subscription-card .white-card .card-heading {
  color: #443172;
}

.subscription-plan .subscription-card .white-card .des {
  color: #000;
}

.subscription-plan .subscription-card .white-card .price {
  color: #000;
}
.link.m-link {
  position: relative;
}
.tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 10px;
  position: absolute;
  z-index: 1;
  top: -35px;
  font-size: 11px;
  width: 90%;
}

.link.m-link:hover .tooltiptext {
  visibility: visible;
}
@media (max-width: 767px) {
  .subscription-plan .subscription-card {
    display: block;
  }

  .subscription-plan .subscription-card .purple-card {
    width: 100%;
  }

  .subscription-plan .heading {
    font-size: 31px;
  }

  .subscription-plan .sub-text {
    width: 100%;
    font-size: 12px;
    text-align: center;
  }

  .subscription-plan .subscription-card .white-card {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .subscription-plan .sub-right {
    margin-top: 30px;
  }

  .subscription-plan .subscription-card .des {
    width: 100%;
  }
  .subscription-plan .sub-right img {
    position: unset;
    width: 100%;
    height: auto;
  }
  .subscription-plan {
    padding-bottom: 0px;
  }
}
