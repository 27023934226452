.beauty-page {
  background: url("../Assets/img/beauty/beauty-bg.webp");
  background-color: #42346f;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 30px;
  text-align: center;
}
.beauty-page .image {
  max-width: 375px;
  margin: auto;
}
.beauty-page .l-btn {
}
.beauty-page .l-btn a {
  display: inline-block;
  background-color: #e13998;
  color: #fff;
  text-decoration: none;
  padding: 8px 50px;
  border-radius: 25px;
  margin-top: 25px;
}
.beauty-page .l-btn a:hover {
}
.beauty-page .image img {
  width: 100%;
  height: auto;
}

@media (max-width: 440px) {
  .beauty-page {
    background: url("../Assets/img/beauty/beauty-mb-bg.webp");
    background-position: bottom left;
  }
  .beauty-page .image {
    max-width: 95%;
  }
}
