@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400,500,600&display=swap");
.jw-main-padding {
  padding: 60px 0px;
}
a {
  cursor: pointer;
  text-decoration: none !important;
}
.section-inner-margin {
  padding: 30px 0px;
}
.jazzworld-wraper {
  font-family: "Inter", sans-serif;
  background-image: url("../Assets/img/zong-world/zong-world-bg.webp");
  background-size: cover;
  background-color: #1f1f1f;
}

.jazzworld-wraper .header {
  background-color: #1f1f1f;
  padding: 15px 0;
}

.jazzworld-wraper .header .main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jazzworld-wraper .header .main-header .logo img {
  width: 90px;
}

.jazzworld-wraper .header .main-header .buttons {
  display: flex;
  column-gap: 10px;
}

.jazzworld-wraper .header .main-header .buttons a {
  padding: 7px 12px;
  background-color: #2d2d2d;
  border: 1px solid #646464;
  color: #e2e2e2;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  transition: 0.3s all linear;
}

.jazzworld-wraper .header .main-header .buttons button:hover {
  cursor: pointer;
  opacity: 0.6;
}
.discounted-swiper-wraper .discounted-swiper-card {
  padding: 50px 40px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  min-height: 304px;
}
.discounted-swiper-wraper .discounted-swiper-card .abs-image {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 225px;
}
.discounted-swiper-wraper .discounted-swiper-card.green-card .abs-image {
  width: 270px;
}
.discounted-swiper-wraper .discounted-swiper-card.red-card .abs-image {
  width: 140px;
  bottom: 10px;
  right: 10px;
}
.discounted-swiper-wraper .discounted-swiper-card.pink-card {
  background: linear-gradient(250.43deg, #c2fefa 0%, #ffafd1 100%);
}
.discounted-swiper-wraper .discounted-swiper-card.green-card {
  background: linear-gradient(250.43deg, #cafec2 0%, #ffacac 100%);
}
.discounted-swiper-wraper .discounted-swiper-card.door-step {
  background: linear-gradient(250.43deg, #fedec8 0%, #afeafd 100%);
}
.discounted-swiper-wraper .discounted-swiper-card.red-card {
  background: linear-gradient(250.43deg, #c2fef3 0%, #ffdeac 100%);
  padding: 66px 40px;
}

.discounted-swiper-wraper .discounted-swiper-card .discounted-text p {
  color: #4f4f4f;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
}
.discounted-swiper-wraper .discounted-swiper-card .discounted-text h3 {
  color: #252525;
  font-weight: 600;
  font-size: 27px;
  line-height: 38px;
  margin: 10px 0px 30px;
}
.discounted-swiper-wraper
  .discounted-swiper-card.door-step
  .discounted-text
  h3 {
  color: #3d668b;
}
.discounted-swiper-wraper .discounted-swiper-card .discounted-text span {
  display: inline-block;
  background-color: #e120b1;
  color: #fff;
  font-weight: 700;
  line-height: 24px;
  padding: 7px 11px;
  border-radius: 7px;
  transform: rotate(3deg);
}
.discounted-swiper-wraper
  .discounted-swiper-card
  .discounted-text
  span.twentydisc {
  color: #f62c2b;
  font-size: 65px;
  padding: unset !important;
  transform: unset;
  font-weight: 600;
  background-color: unset !important;
}
.discounted-swiper-wraper
  .discounted-swiper-card.green-card
  .discounted-text
  span {
  background-color: #40797a;
}
.book-now a {
  padding: 10px 15px;
  border-radius: 8px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  border: none;
  display: inline-block;
  transition: 0.3s ease;
}
.book-now a img {
  width: 15px;
  margin-left: 10px;
}
.book-now a:hover {
  opacity: 0.7;
  color: #fff;
}
.book-now.pink-btn a {
  background-color: #e120b1;
}
.book-now.green-btn a {
  background-color: #40797a;
}
.book-now.red-btn a {
  background-color: #f62c2b;
}
.book-now.door-btn a {
  background-color: #3d668b;
}

/* discount section ends here */

.available-packages .package-text h2 {
  font-size: 30px;
  font-weight: 700;
  color: #ebebeb;
}

/* .available-packages .swiper-slider {
} */

.available-packages .discounted-packages.blue-card {
  background: linear-gradient(224.89deg, #88b8ed 2.9%, #0d5ca5 100%);
}
.available-packages .discounted-packages.pink-card {
  background: linear-gradient(224.89deg, #fd9c8b 2.9%, #6112c6 100%);
}
.available-packages .discounted-packages.green-card {
  background: linear-gradient(224.89deg, #76d3aa 2.9%, #ffad69 100%);
}
.available-packages .discounted-packages.purple-card {
  background: linear-gradient(224.89deg, #65fff6 2.9%, #e120b1 100%);
}
.available-packages .discounted-packages {
  padding: 30px 35px;
  border-radius: 30px;
}
.available-packages .discounted-packages .discount-text p {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}
.available-packages .discounted-packages .discount-text .fiftyperoff {
  font-weight: 600;
  font-size: 55px;
  color: #fff;
}
.available-packages .discounted-packages .discount-text .fiftyperoff span {
  font-weight: 400;
  font-size: 18px;
}
.main-heading {
  font-size: 30px;
  font-weight: 700;
  color: #ebebeb;
}
.services-packages {
  text-align: center;
  /* padding: 40px 0px 30px; */
}
.services-packages img {
  width: 100px;
}
.services-packages h3 {
  color: #afafaf;
  font-size: 15px;
  margin-top: 25px;
}
.ser-img {
  display: flex;
  height: 120px;
  justify-content: center;
  align-items: flex-end;
}
.information .text-info h4 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}
.slider-info {
  position: relative;
}
.slider-info .swiper-button-prev,
.slider-info .swiper-button-next {
  position: unset !important;
  margin-top: auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
}
.slider-info .swiper-button-next svg path {
  fill: #252525 !important;
}
.slider-info .swiper-button-next::after {
  content: none !important;
}
.slider-info .swiper-button-prev::after {
  content: none !important;
}
.slider-info .swiper-button-prev svg,
.slider-info .swiper-button-next svg {
  width: 20px;
}
.slider-info .swiper-button-next::after {
  content: none !important;
}
.swiper-btn-flex {
  display: flex;
  column-gap: 20px;
  margin-top: 30px;
  align-items: center;
}
.information .slider-info .health-info .info-img {
  position: relative;
  border-radius: 17px;
}
.show-all-button a {
  border: 2.41px solid #646464;
  background-color: #2d2d2d;
  padding: 10px 30px;
  border-radius: 25px;
  font-size: 18px;
  color: #e2e2e2;
  transition: 0.3s linear;
  text-align: center;
}
.show-all-button a:hover {
  opacity: 0.7;
  color: #fff;
}
.information .slider-info .health-info .info-img img {
  border-radius: 17px;
}

.information .slider-info .health-info .icon-text {
  position: absolute;
  bottom: 10%;
  left: 8%;
}

.information .slider-info .health-info .icon-text img {
  width: 50px;
  margin-bottom: 5px;
}

.information .slider-info .health-info .icon-text p {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  width: 75%;
  margin-top: 7px;
}

.health-tips .health-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.health-text a {
  color: #afafaf;
  font-size: 17px;
  font-weight: 500;
  transition: 0.3s linear;
}
.health-text a:hover {
  color: #fff;
  opacity: 0.7;
}
.main-diet {
  padding: 25px 20px;
  border-radius: 18px;
  display: flex;
  column-gap: 12px;
  height: 260px;
}
.main-diet .image img {
  width: 365px;
}
.main-diet.bg-red {
  background: #fadfdf;
}
.main-diet.bg-yellow {
  background: #faf8df;
}
.main-diet.bg-blue {
  justify-content: space-between;
  background: #ddf2fd;
}
.main-diet.bg-blue .image img {
  width: 157px;
}
.main-diet.bg-red .readmorebtn {
  background-color: #c19696;
}
.main-diet.bg-yellow .readmorebtn {
  background-color: #bec196;
}
.main-diet.bg-blue .readmorebtn {
  background-color: #96a4c1;
}
.main-diet .readmorebtn img {
  width: 15px;
}
.slider-text p {
  font-size: 24px;
  font-weight: 600;
  color: #252525;
  line-height: 32px;
  min-height: 96px;
}
.readmorebtn {
  display: inline-block;
  margin-top: 20px;
  padding: 8px 14px;
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  border-radius: 22px;
  border: none;
  transition: 0.3s linear;
}
.readmorebtn:hover {
  opacity: 0.7;
  color: #fff;
}
.footer.zong-world-footer {
  background-color: #2c3248;
}
.slider-health .swiper {
  padding-bottom: 40p;
}
.swiper-pagination {
  position: unset !important;
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  align-items: center;
}
.swiper-pagination-bullet {
  background-color: #b3becc !important;
  min-width: 12px;
  min-height: 12px;
}
.swiper-pagination-bullet-active {
  width: 40px !important;
  height: 12px !important;
  border-radius: 9px !important;
  background-color: #a3a7ad !important;
}
/* modal  */
.popup-section .modal-content {
  position: relative;
  display: flex;
  margin: auto;
  flex-direction: column;
  width: 47%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: 16px;
  outline: 0;
}

.zong-world-popup .zong-world-content {
  max-width: 100%;
  color: #ffffff;
  margin: auto;
  background-color: rgba(37, 37, 37, 1);
  width: 100%;
  text-align: center;
  padding: 20px 50px;
}
.zong-world-popup .zong-world-content .zong-world-heading {
  color: #ebebeb;
  font-size: 22px;
  font-weight: 500;
  margin: auto;
  width: 64%;
}
.zong-world-popup .zong-world-content .zong-world-popup-form {
  padding: 0px 10px;
}
.zong-world-popup .zong-world-content .zong-world-popup-form .error {
  color: rgba(249, 71, 71, 1);
  margin: 10px 0px;
  font-size: 13px;
}
.zong-world-popup .zong-world-content .zong-world-popup-form .show-count {
  text-align: center;
  display: inline;
}
.zong-world-popup .zong-world-content .zong-world-popup-input {
  display: flex;
  align-items: center;
  gap: 14px;
  margin: 15px 0px;
}
.zong-world-popup .zong-world-content .zong-world-popup-input span {
  padding: 12px 10px;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid black;
  border-radius: 12px;
  font-size: 13px;
}
.zong-world-popup .zong-world-content .zong-world-popup-input input {
  background-color: #ffffff;
  border: 1px solid black;
  color: #000000;
  font-size: 13px;
  width: 61%;
  padding: 12px 14px;
  /* padding: 15px 10px; */
  width: 100%;
  border-radius: 12px;
}

.zong-world-popup .zong-world-content button {
  box-shadow: 0px 0px 9px -4px #000000;
  width: 100%;
  border-radius: 25px;
  padding: 12px 20px;
  font-size: 16px;
  color: #ffffff;
  border: 1px solid #8c8c8c;
  background-color: rgba(45, 45, 45, 1);
  font-weight: 400;
  margin: 15px 0px;
}
.zong-world-popup .zong-world-content button:hover {
  opacity: 0.7;
}
.zong-world-popup .zong-world-content .text {
  color: #afafaf;
  font-size: 14px;
  margin: 11px 0px;
  font-weight: 400;
  min-height: unset;
}
.zong-world-popup .zong-world-content .charges-text {
  font-size: 14px;
  margin: 10px 0px;
  color: #ebebeb;
  font-weight: 400;
}
/* PIN page */
.zong-world-popup .zong-world-content .otp-form {
  padding: 0px 10px;
}
.zong-world-popup .zong-world-content .otp-form .zong-world-popup-input {
  display: flex;
  align-items: center;
  width: 83%;
  border-radius: 25px;
  font-size: 14px;
  margin: auto;
  color: #ffffff;
  border: 1px solid transparent;
  /* margin: 15px 0px; */
  gap: 20px;
  background-color: transparent;
  margin-bottom: 10px;
  margin-top: 15px;
}
.zong-world-popup .zong-world-content .otp-form .zong-world-popup-input input {
  border: none;
  color: #000000;
  font-size: 13px;
  width: 61%;
  border: 1px solid transparent;
  padding: 10px 0;
  margin: auto;
  width: 36%;
  border-radius: 6px;
  background-color: #ffffff;
  text-align: center;
}

.charges-text .resend-code .timer-text {
  font-size: 14px;
  color: #afafaf;
  font-weight: 300;
}
.charges-text .resend-code .timer-text span {
  color: #d5d5d5;
  text-decoration: underline;
  cursor: pointer;
}
/* modal  */
.zong-world-footer a {
  transition: 0.3s ease-in;
}
.zong-world-footer a:hover {
  font-weight: unset !important;
  color: #fff;
}
.life-seht-section .life-seht-card {
  background-color: #282a2c;
  padding: 40px;
  border-radius: 26px;
}
.life-seht-section .life-seht-card .life-seht-heading {
  color: #ebebeb;
  font-size: 28px;
  font-weight: 700;
}
.life-seht-section .life-seht-card .life-seht-heading-parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #4b4b4b;
  padding-bottom: 20px;
}
.life-seht-section .life-seht-card .life-seht-heading-parent span {
  background-color: #a42cff;
  padding: 8px 12px;
  font-weight: 600;
  color: #fff;
  font-size: 15px;
  line-height: 15px;
  display: inline-block;
  border-radius: 22px;
}
.life-seht-section .life-seht-card .life-seht-heading-parent span.orange {
  background-color: #ff5e2c;
}
.life-seht-section .life-seht-card ul {
  padding-left: 0px !important;
  margin-top: 30px;
  min-height: 530px;
}
.life-seht-section .life-seht-card ul li {
  color: #afafaf;
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 20px;
  list-style: none;
  display: flex;
  column-gap: 20px;
}
.life-seht-section .life-seht-card ul li:last-child {
  margin-bottom: 0px;
}
.life-seht-section .life-seht-card ul li img {
  width: 28px;
  height: 28px;
}

.life-seht-section .life-seht-card .subscribe-package-btn button,
.life-seht-section .life-seht-card .subscribe-package-btn a {
  display: inline-block;
  background-color: #282a2c;
  border: 1px solid #626262;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  border-radius: 30px;
  padding: 8px 0px;
  margin-top: 40px;
  transition: 0.3s linear;
}
.life-seht-section .life-seht-card .subscribe-package-btn button:hover,
.life-seht-section .life-seht-card .subscribe-package-btn a:hover {
  opacity: 0.7;
}
@media only screen and (max-width: 991px) {
  .zong-world-popup .zong-world-content .zong-world-heading {
    font-size: 16px;
  }
  .zong-world-popup .zong-world-content .text {
    font-size: 13px;
  }
  .zong-world-popup .zong-world-content button {
    font-size: 14px;
  }
  .charges-text .resend-code .timer-text {
    font-size: 13px;
  }
  .zong-world-popup .zong-world-content .charges-text {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .jw-main-padding {
    padding: 40px 0px;
  }
  .discounted-swiper-wraper .discounted-swiper-card {
    padding: 20px;
    border-radius: 20px;
    min-height: unset;
  }
  .discounted-swiper-wraper .discounted-swiper-card .discounted-text h3 {
    font-size: 18px;
    line-height: 24px;
    margin: 10px 0px 20px;
  }
  .discounted-swiper-wraper .discounted-swiper-card .abs-image {
    width: 140px;
  }
  .book-now a {
    font-size: 15px;
  }
  .discounted-swiper-wraper .discounted-swiper-card .discounted-text p {
    font-size: 20px;
    line-height: 30px;
  }
  .discounted-swiper-wraper .discounted-swiper-card .discounted-text span {
    line-height: 20px;
  }
  .discounted-swiper-wraper .discounted-swiper-card.green-card .abs-image {
    width: 150px;
  }
  .discounted-swiper-wraper .discounted-swiper-card.red-card {
    padding: 20px;
    height: 195px;
  }
  .discounted-swiper-wraper
    .discounted-swiper-card
    .discounted-text
    span.twentydisc {
    font-size: 32px;
  }
  .discounted-swiper-wraper .discounted-swiper-card.red-card .abs-image {
    width: 95px;
  }
  .available-packages .discounted-packages {
    padding: 20px;
    border-radius: 18px;
  }
  .available-packages .discounted-packages .discount-text .fiftyperoff {
    font-size: 36px;
  }
  .available-packages .discounted-packages .discount-text p {
    font-size: 18px;
    line-height: 24px;
  }
  .services-packages h3 {
    line-height: 23px;
  }
  .section-inner-margin {
    padding: 30px 0px 0px;
  }
  .main-heading {
    font-size: 22px;
  }
  .slider-text p {
    font-size: 18px;
    line-height: 25px;
  }
  .main-diet {
    height: 200px;
  }
  .main-diet .slider-text p br {
    display: none;
  }
  .zong-world-popup .zong-world-content {
    padding: 20px 0px;
  }
  .zong-world-popup .zong-world-content .zong-world-heading {
    font-size: 13px;
    width: 68%;
  }
  .zong-world-popup .zong-world-content button {
    font-size: 13px;
  }
  .zong-world-popup .zong-world-content .otp-form .zong-world-popup-input {
    width: 100%;
  }
  .zong-world-popup .zong-world-content .zong-world-popup-form {
    padding: 0px 10px;
  }
  .life-seht-section .life-seht-card {
    padding: 20px;
    margin-bottom: 25px;
    border: 1.07px solid #626262;
    border-radius: 16px;
  }
  .life-seht-section .life-seht-card ul li {
    font-size: 15px;
    column-gap: 12px;
  }
  .life-seht-section .life-seht-card ul {
    min-height: unset;
  }
  .life-seht-section .life-seht-card .subscribe-package-btn a {
    margin-top: 20px;
    font-size: 17px;
  }
  .life-seht-section .life-seht-card .life-seht-heading {
    font-size: 20px;
  }
  .life-seht-card.last-child {
    margin-bottom: 0px;
  }
}
@media (max-width: 360px) {
  .discounted-swiper-wraper .discounted-swiper-card .abs-image {
    width: 100px;
  }
  .discounted-swiper-wraper .discounted-swiper-card.green-card .abs-image {
    width: 112px;
  }
  .discounted-swiper-wraper .discounted-swiper-card.red-card .abs-image {
    width: 66px;
  }
  .available-packages .discounted-packages .discount-text .fiftyperoff {
    font-size: 30px;
  }
  .available-packages .discounted-packages .discount-text p {
    font-size: 16px;
    line-height: 20px;
  }
}
