.awareness-page {
  background-image: url("../Assets/img/awareness-bg.webp");
  min-height: calc(100vh + 150px);
  padding-bottom: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #443173;
}
.awareness-page .image {
  display: none;
}

.awareness-page .image img {
  width: 100%;
}

@media (max-width: 991px) {
  .awareness-page {
    background: #443173;
    min-height: 100vh;
  }
  .awareness-page .image {
    display: block;
    max-width: 375px;
    margin: auto;
  }
}

@media (max-width: 450px) {
  .awareness-page .image {
    max-width: 100%;
    padding: 0;
  }
}
